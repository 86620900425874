import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PayingempShareService {

  constructor(private datepipe:DatePipe) { }

  public empView_id = new BehaviorSubject<any>('');
  public payroll = new BehaviorSubject<any>('');
  public employeepf = new BehaviorSubject<any>('');
  public empNewId= new BehaviorSubject<any>('')
  public PdfExcelCurrentTimeDate(){
    let currentDate=new Date()
    let hour=this.datepipe.transform(currentDate,'h')
    let min=currentDate.getMinutes()
    let AmPm=this.datepipe.transform(currentDate,'a')
    let date=this.datepipe.transform(currentDate,'MMM')
    let year=this.datepipe.transform(currentDate,'yyyy')
    let total=date+'-'+year+'-'+'('+ hour+'.'+min+' '+AmPm+')'
    // let obj={
    //   'data':total
    // }
    return total
  }
 
}
