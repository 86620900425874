export class APIServicesPath {

    // API_Service_Path = { 
    //         "master_Service": "mstserv/",
    //         "attendance_Service": "atdserv/",
    //         "user_Service": "usrserv/",
    //         "CMS_Service": "cmsserv/" 
    // }


}