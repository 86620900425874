export class UtilFiles {
  static WeekDays() {
    let WeekDays = [

      { name: "sun", Display: "Sunday" },
      { name: "mon", Display: "Monday" },
      { name: "tue", Display: "Tuesday" },
      { name: "wed", Display: "Wednesday" },
      { name: "thu", Display: "Thursday" },
      { name: "fri", Display: "Friday" },
      { name: "sat", Display: "Saturday" }
    ]
    return WeekDays
  }



  Months = [
    { name: "Jan", Display: "January", num: 1 },
    { name: "Feb", Display: "Monday", num: 2 },
    { name: "Mar", Display: "Tuesday", num: 3 },
    { name: "April", Display: "Wednesday", num: 4 },
    { name: "May", Display: "Thursday", num: 5 },
    { name: "Jun", Display: "Friday", num: 6 },
    { name: "Jul", Display: "Saturday", num: 7 },
    { name: "Aug", Display: "Sunday", num: 8 },
    { name: "Sep", Display: "Monday", num: 9 },
    { name: "Oct", Display: "Tuesday", num: 10 },
    { name: "Nov", Display: "Wednesday", num: 11 },
    { name: "Dec", Display: "Thursday", num: 12 }
  ]


  BooleanSet = [
    { id: true, text: "Yes" },
    { id: false, text: "No" }
  ]

















}
