export class MenuFilesHRMS {
    constructor() {

    }

    EMP_details_Menu_List: any = [{ name: 'Emp Info', conditionName: "EmpInfo" }, { name: 'Family Info', conditionName: "FamilyInfo" },
    { name: 'Education Info', conditionName: "EducationInfo" }, { name: 'Experience', conditionName: "Experience" },
    { name: 'Bank Details', conditionName: "BankDetails" }, { name: 'Emergency Contact', conditionName: "EmergencyContact" }]
    

}
